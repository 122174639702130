import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from "../../shared/services/user.service";
import { Router} from "@angular/router";
import {EventService} from "../../shared/services/event.service";
import { EventInfo} from "../../shared/models/event";
declare interface RouteInfo {
    path?: string;
    title: string;
    icon: string;
    class: string;
   children?: RouteInfo[]
}
export const ROUTES: RouteInfo[] = [
    { path: '/organization', title: 'routes.organization',  icon:'groups', class: '' },
    { path: '/device', title: 'routes.devices',  icon:'device_hub', class: '' },
    { path: '/profile', title: 'routes.account',  icon:'person', class: '' }
];

export const ROUTESEVENT: RouteInfo[] = [
  { path: '/grid/list', title: 'routes.dashboard',  icon: 'grid_view', class: '' },
  { path: '/map', title: 'routes.map',  icon:'map', class: '' },
  { path: '/analysis/list', title: 'routes.analysis',  icon:'analytics', class: '' },
  { path: '/profile', title: 'routes.export',  icon:'download', class: '' }
];

export const ROUTESSETTINGS: RouteInfo[] = [
  { path: '/settings/general', title: 'routes.general',  icon: 'grid_view', class: '' },
  { path: '/settings/areas', title: 'routes.areas',  icon:'map', class: '' },
  { path: '/settings/k', title: 'routes.devices',  icon:'analytics', class: '' },
  { path: '/settings/users', title: 'routes.users',  icon:'download', class: '' },
  { path: '/settings/roles', title: 'routes.roles',  icon:'settings', class: '' },
  // { path: '/settings/b', title: 'routes.router',  icon:'settings', class: '' },
  { path: '/settings/reset-count-bots', title: 'routes.reset',  icon:'settings', class: '' },
  { path: '/settings/d', title: 'routes.import',  icon:'settings', class: '' },
  { path: '/settings/representation', title: 'routes.representation',  icon:'settings', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit , OnDestroy{
  menuItems: RouteInfo[] | undefined;
  eventDetail!: EventInfo;

  childrenMenuItem: RouteInfo[] | undefined;
  settingsMenuItem: RouteInfo[] | undefined;

  isSettingsOpen = false;
  isEventItemsOpen = false;

  constructor(private userService: UserService, private router: Router, private eventService: EventService) {

  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.childrenMenuItem = ROUTESEVENT.filter(childrenItem => childrenItem);
    this.settingsMenuItem = ROUTESSETTINGS.filter(settingMenu => settingMenu);
    this.getEventData();

    this.router.events.subscribe(() => {
      const currentRoute = this.router.url;

      // Open event details menu
      if (currentRoute.includes('/event/')) {
        this.isEventItemsOpen = true;
      }

      // Open settings menu
      if (currentRoute.includes('/settings')) {
        this.isSettingsOpen = true;
        this.isEventItemsOpen = true;
      }
    });
  }

  getEventData(){
    this.eventService.getEventsDetailWithOutStore().subscribe(res => {
      this.eventDetail = res.event;
    })

  }
  isMobileMenu() {
      return window.innerWidth <= 991;

  };

  logout() {
    this.userService.logout().then(() => this.router.navigate(['/account/login']));
  }
  ngOnDestroy() {
    // this.routeSubscription?.unsubscribe();
  }
}
