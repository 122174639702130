import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from "../../shared/services/user.service";
import { Router} from "@angular/router";
import {EventService} from "../../shared/services/event.service";
import { EventInfo} from "../../shared/models/event";
import {EyesConfigurationService} from "../../shared/services/eyes-configuration.service";
import {NotesService} from "../../shared/services/notes.service";
import {Note} from "../../shared/models/note";
import {AddNoteFormComponent} from "../../main/organization/note-manager/add-note-form/add-note-form.component";
import {MatDialog} from "@angular/material/dialog";
import {CHANGE_LOG_ENTRIES} from "../../shared/utility/change-log";
import {NewsComponent} from "../../shared/components/news/news.component";
import {User} from "../../auth/user.model";
import {
  AdminROUTES, generateEventRoutes, generateSettingRoutes,
  RouteInfo,
  ROUTES,
  ROUTESEVENT,
  ROUTESSETTINGS
} from "../../shared/utility/routers";


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit , OnDestroy{
  menuItems: RouteInfo[] | undefined;
  eventDetail!: EventInfo;

  childrenMenuItem: RouteInfo[] | undefined;
  settingsMenuItem: RouteInfo[] | undefined;

  isSettingsOpen = false;
  isEventItemsOpen = false;
  favoriteListRoutes: any[] | undefined = [];
  favoriteList: any[] | undefined = [];
  isFavoritesOpen: boolean = false;

  logs = CHANGE_LOG_ENTRIES;
  newsList: string[] = [];
  userDetails!: User;

  constructor(private userService: UserService,
              private eyedConfigService: EyesConfigurationService,
              private router: Router,
              private noteService: NotesService,
              private dialog: MatDialog,
              private eventService: EventService) {

  }

  ngOnInit() {

    // this.childrenMenuItem = ROUTESEVENT.filter(childrenItem => childrenItem);
    // this.settingsMenuItem = ROUTESSETTINGS.filter(settingMenu => settingMenu);
    this.getEventData();
    this.getUserConfig();
    this.getUserDetails()

    this.router.events.subscribe(() => {
      const currentRoute = this.router.url;

      // Open event details menu
      if (currentRoute.includes('/event/')) {
        this.isEventItemsOpen = true;
      }

      // Open settings menu
      if (currentRoute.includes('/settings')) {
        this.isSettingsOpen = true;
        this.isEventItemsOpen = true;
      }
    });
  }

  getUserDetails(){
    this.userService.selectCurrentUser().subscribe(res => {
      if(res){
        this.userDetails = res;
        if(this.userDetails.is_superuser){
          this.menuItems = AdminROUTES.filter(menuItem => menuItem);
        }else{
          this.menuItems = ROUTES.filter(menuItem => menuItem);
        }
      }

    })
  }


  getUserConfig(){
    this.eyedConfigService.configDetails().subscribe( res => {
      if (res) {
        this.newsList = [];
        this.logs.forEach( date => {
          if(date > res.configuration.read_news){
            this.newsList.push(date);
          }
        })
      this.favoriteListRoutes = [];
      this.favoriteList = res?.configuration.favorite_notes;
      this.favoriteList?.forEach((favId) => {
        this.noteService.getById(favId).then(res => {
          this.favoriteListRoutes?.push({
            path: res,
            title: res?.title!, icon: '', class: ''
          })
        })
      })
    }
    })
  }

  openModal(row?: Note) {
    const dialogRef = this.dialog.open(AddNoteFormComponent, {
      width: '800px',
      maxWidth: '100%',
      maxHeight: '95%',
      data: {mode: 'UPDATE', details: row}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        // if (mode == 'CREATE'){
        //   this.createNote(result)
        // }else{
        //   this.editNote(result, row)
        // }
      }
    });
  }

  getEventData(){
    this.eventService.getEventsDetailWithOutStore().subscribe(res => {
      if(res){
        this.eventDetail = res.event;
        if(res?.my_permissions?.length){
          this.childrenMenuItem = generateEventRoutes(res.my_permissions)
          this.settingsMenuItem = generateSettingRoutes(res.my_permissions);
        }
      }

    })

  }

  isMobileMenu() {
      return window.innerWidth <= 991;

  };

  logout() {
    this.userService.logout().then(() => this.router.navigate(['/account/login']));
  }
  ngOnDestroy() {
    // this.routeSubscription?.unsubscribe();
  }

  openNews() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('nav-open');
    const dialogRefList = this.dialog.open(NewsComponent, {
      width: '500px',
      maxWidth: '100%',
      height: '100%',
    });
  }
}
