<div class="container">
  <div class="col-12  col-md-8 offset-md-2 col-lg-6 offset-lg-3">
    <div class="box-middle">
      <div class="card card-signup p-0 p-sm-4 shadow-lg">

        <div class="img-container my-4 card-header" >
          <img src="./assets/img/app-logo.png" alt="">
        </div>
        <div class="card-body mx-auto">
          <h1 class="text-center">404</h1>
        <p class="text-center">

          {{'notFound' | translate}}

        </p>

          <div class="text-center mt-5">
            <a routerLink="/events" class="text-Primary text-decoration"> {{'backToHome' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <app-change-language-button></app-change-language-button>
    </div>
  </div>
</div>
