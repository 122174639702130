<div class="d-flex justify-content-between py-2 px-2 " >
  <h2 mat-dialog-title>{{'news.title' | translate}}</h2>

  <button mat-icon-button aria-label="close" (click)="closeSideBar()" >
    <mat-icon >close</mat-icon>
  </button>
</div>
<div class="pr-2">

<ul class="timeline timeline-simple ">
  <li class="timeline-inverted mb-3" *ngFor="let log of logs">

    <div class="timeline-panel">
    <div class="timeline-heading d-flex">
      <div mat-card-avatar><mat-icon>newspaper</mat-icon></div>
      <div class="pl-2">
        <span class="badge badge-info"> {{(log | slice: 0:10) | date: 'mediumDate'}}</span>
      </div>
    </div>
      <div class="timeline-body">
        <p>{{'news.logs.' + log | translate}}</p>
      </div>
<!--      <h6><i class="ti-time"></i> 11 hours ago via Twitter </h6>-->
    </div>
  </li>
<!--  <li class="timeline-inverted">-->
<!--    <div class="timeline-badge success">-->
<!--      <i class="material-icons">extension</i>-->
<!--    </div><div class="timeline-panel">-->
<!--    <div class="timeline-heading">-->
<!--      <span class="badge badge-success">Another One</span></div>-->
<!--    <div class="timeline-body">-->
<!--      <p>Thank God for the support of my wife and real friends. I also wanted to point out that it’s the first album to go number 1 off of streaming!!! I love you Ellen and also my number one design rule of anything I do from shoes to music to homes is that Kim has to like it....</p>-->
<!--    </div>-->
<!--  </div>-->
<!--  </li>-->
</ul>
</div>
