import { Component } from '@angular/core';
import {CHANGE_LOG_ENTRIES} from "../../utility/change-log";
import {DatePipe, NgForOf, SlicePipe} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  imports: [
    NgForOf,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    TranslateModule,
    SlicePipe,
    DatePipe
  ],
  standalone: true
})
export class NewsComponent {
  constructor(public dialogRef: MatDialogRef<NewsComponent>) {
  }

  logs = CHANGE_LOG_ENTRIES;

  closeSideBar() {
this.dialogRef.close()
  }
}
