import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from "../../shared/services/user.service";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {EventService} from "../../shared/services/event.service";
import {EventDetail, EventInfo} from "../../shared/models/event";
import {filter, Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/app.state";

declare interface RouteInfo {
    path?: string;
    title: string;
    icon: string;
    class: string;
   children?: RouteInfo[]
}
export const ROUTES: RouteInfo[] = [
    { path: '/organization', title: 'Organizations',  icon:'groups', class: '' },
    { path: '/device', title: 'Devices',  icon:'device_hub', class: '' },
    { path: '/profile', title: 'Account',  icon:'person', class: '' }
];

export const ROUTESEVENT: RouteInfo[] = [
  { path: '/grid/list', title: 'Dashboard',  icon: 'grid_view', class: '' },
  { path: '/map', title: 'Map',  icon:'map', class: '' },
  { path: '/analysis/list', title: 'Analysis',  icon:'analytics', class: '' },
  { path: '/profile', title: 'Export',  icon:'download', class: '' },
  { path: '/profile', title: 'Settings',  icon:'settings', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit , OnDestroy{
  menuItems: RouteInfo[] | undefined;
  eventDetail!: EventInfo;

  childrenMenuItem: RouteInfo[] | undefined;

  constructor(private userService: UserService, private router: Router, private eventService: EventService) {

  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.childrenMenuItem = ROUTESEVENT.filter(childrenItem => childrenItem);
    this.getEventData();
  }

  getEventData(){
    this.eventService.getEventsDetailWithOutStore().subscribe(res => {
      this.eventDetail = res.event;
    })

  }
  isMobileMenu() {
      return window.innerWidth <= 991;

  };

  logout() {
    this.userService.logout().then(() => this.router.navigate(['/account/login']));
  }
  ngOnDestroy() {
    // this.routeSubscription?.unsubscribe();
  }
}
