

export interface AppState {
  user: any;
  events: any,
  grids: any,


}

export const appReducer = {

}
