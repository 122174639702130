export const CHANGE_LOG_ENTRIES = [
  '2025-03-10_04',
  '2025-03-10_03',
  '2025-03-10_02',
  '2025-03-10_01',
  '2022-12-30_01',
  '2022-12-22_01',
  '2022-09-15_01',
  '2022-07-14_01',
];
