<div class="logo">
    <a routerLink="/" class="simple-text ">
        <div class="logo-img logo-normal">
            <img src="/assets/img/app-logo.png"/>
        </div>
<!--      <div class="logo-img logo-mini">-->
<!--        <img src="/assets/img/app-icon.png"/>-->
<!--      </div>-->
    </a>

</div>
<div class="sidebar-wrapper">
  <div *ngIf="isMobileMenu()">
    <ul class="nav navbar-nav nav-mobile-menu">

        <li class="nav-item dropdown" (click)="openNews()">
            <a class="nav-link" id="notification">
                <i class="material-icons">notifications</i>
                <span class="notification">{{newsList?.length}}</span>
                <p>
                    <span class="d-lg-none d-md-block">{{'news.shortTitle' | translate}}</span>
                </p>
            </a>
        </li>
    </ul>
  </div>
    <ul class="nav">












      <li class=" nav-item" routerLinkActive="active">
        <a  class="nav-link" [routerLink]="'/events'">
          <i class="material-icons">dashboard</i>
          <p>{{'routes.home' | translate}}</p>
        </a>
      </li>

      <li routerLinkActive="active" class="nav-item" *ngIf="eventDetail">
        <a data-toggle="collapse" class="nav-link collapsed" href="#eventItems" aria-expanded="true">
          <i class="material-icons">festival</i>
          <p>{{eventDetail?.title}}<b class="caret"></b></p></a>
        <div id="eventItems" style="" [ngClass]="{'collapse show': isEventItemsOpen, 'collapse': !isEventItemsOpen}">
          <ul class="nav">
            <li routerLinkActive="active" class="nav-item" *ngFor="let menuItem of childrenMenuItem">
              <a class="nav-link" [routerLink]="'event/'+ eventDetail?.id + menuItem.path">
                <span class="sidebar-mini" >
                  <i class="material-icons">{{menuItem.icon}}</i>
                </span>
                <span class="sidebar-normal"> <p>{{menuItem.title | translate}}</p></span></a>
            </li>
            <li routerLinkActive="active" class="nav-item" >
              <a data-toggle="collapse" class="nav-link collapsed" href="#settingItems" aria-expanded="true">
                <i class="material-icons">settings</i>
                <p>{{'routes.settings' | translate}}<b class="caret"></b></p></a>
              <div [ngClass]="{'collapse show': isSettingsOpen, 'collapse': !isSettingsOpen}" id="settingItems" style="">
                <ul class="nav">
                  <li routerLinkActive="active" class="nav-item ml-3" >
                    <a class="nav-link" [routerLink]="'event/'+ eventDetail?.id + '/settings/general'">
                      <span class="sidebar-normal"> <p>- {{'routes.general' | translate}}</p></span></a>
                  </li>

                  <li routerLinkActive="active" class="nav-item ml-3" *ngFor="let menuItem of settingsMenuItem">
                    <a class="nav-link" [routerLink]="'event/'+ eventDetail?.id + menuItem.path">
                      <span class="sidebar-normal"> <p>- {{menuItem.title | translate}}</p></span></a>
                  </li>
                  <li routerLinkActive="active" class="nav-item ml-3" >
                    <a class="nav-link" [routerLink]="'event/'+ eventDetail?.id + '/settings/representation'">
                      <span class="sidebar-normal"> <p>- {{'routes.representation' | translate}}</p></span></a>
                  </li>

                </ul>
              </div>
            </li>

          </ul>
        </div>
       </li>

        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
<!--          <ng-container *ngIf="!menuItem?.children?.length">-->
            <a  class="nav-link" [routerLink]="[menuItem?.path]">
              <i class="material-icons">{{menuItem.icon}}</i>
              <p>{{menuItem.title | translate}}</p>
            </a>


        </li>

      <li routerLinkActive="active" class="nav-item" *ngIf="favoriteList?.length">
        <a data-toggle="collapse" class="nav-link collapsed" href="#isFavoritesItems" aria-expanded="true">
          <i class="material-icons">star</i>
          <p>Favorites<b class="caret"></b></p></a>
        <div [ngClass]="{'collapse show': isFavoritesOpen, 'collapse': !isFavoritesOpen}" id="isFavoritesItems" style="">
          <ul class="nav">
            <li routerLinkActive="active" class="nav-item ml-3" *ngFor="let menuItem of favoriteListRoutes">
              <a class="nav-link cursor-pointer" (click)="openModal(menuItem.path)">
                <span class="sidebar-normal"> <p>- {{menuItem.title | translate}}</p></span></a>
            </li>

          </ul>
        </div>
      </li>
      <li class=" nav-item" (click)="logout()">
        <a  class="nav-link cursor-pointer">
          <i class="material-icons">logout</i>
          <p>{{'logout' | translate}}</p>
        </a>
      </li>
    </ul>
</div>
