import {isDevMode, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {ComponentsModule} from "./components/components.module";
import {RouterModule} from "@angular/router";
import {AuthInterceptor} from "./interceptors/auth.interceptor";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {ErrorInterceptor} from "./interceptors/error.interceptor";
import {ErrorComponent} from './shared/components/error/error.component';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {NgxMatNativeDateModule} from "@angular-material-components/datetime-picker";
import {StoreModule} from "@ngrx/store";
import {environment} from "../environments/environment";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {appReducer} from "./store/app.state";

export const metaReducers = environment.production ? [] : [];

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatSnackBarModule,
    NgxMatNativeDateModule,
    StoreModule.forRoot(appReducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}


// export function initializeAppCustomLogic(service: AppService): () => Promise<void> {
//   return () =>
//     new Promise((resolve, reject) => {
//       service.initApplication().subscribe({
//         next: (validate) => {
//           resolve()
//         },
//         error: (error) => {
//           service.refreshToken()
//             .pipe(finalize(() => resolve()))
//             .subscribe(noop)
//         }
//       });
//     });
// }
