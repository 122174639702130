<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute fixed-top">
    <div class="container-fluid">
      <button (click)="sidebarToggleDesktop()" class="btn btn-just-icon btn-white btn-fab btn-round" *ngIf="!isMobileMenu()">
        <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
        <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
      </button>
      <button class="navbar-toggler" type="button" (click)="sidebarToggle()">
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
      </button>
        <div class="navbar-wrapper">
          <a class="navbar-brand" href="javascript:void(0)">{{getTitle() | translate}}</a>
        </div>

        <div class="collapse navbar-collapse justify-content-end" id="navigation">
<!--            <form class="navbar-form">-->
<!--                <div class="input-group no-border">-->
<!--                    <input type="text" value="" class="form-control" placeholder="Search...">-->
<!--                    <button  type="submit" class="btn btn-white btn-round btn-just-icon">-->
<!--                        <i class="material-icons">search</i>-->
<!--                        <div class="ripple-container"></div>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </form>-->
            <ul class="navbar-nav">
<!--                <li class="nav-item">-->
<!--                    <a class="nav-link" href="javascript:void(0)">-->
<!--                        <i class="material-icons">dashboard</i>-->
<!--                        <p>-->
<!--                            <span class="d-lg-none d-md-block">Stats</span>-->
<!--                        </p>-->
<!--                    </a>-->
<!--                </li>-->
                <li class="nav-item dropdown">
                    <a class="nav-link"  id="notification" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons">notifications</i>
                        <span class="notification">5</span>
                        <p>
                            <span class="d-lg-none d-md-block">Some Actions</span>
                        </p>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="notification">
                        <a class="dropdown-item" href="javascript:void(0)">Mike John responded to your email</a>
                        <a class="dropdown-item" href="javascript:void(0)">You have 5 new tasks</a>
                        <a class="dropdown-item" href="javascript:void(0)">You're now friend with Andrew</a>
                        <a class="dropdown-item" href="javascript:void(0)">Another Notification</a>
                        <a class="dropdown-item" href="javascript:void(0)">Another One</a>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)" id="account" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons">person</i>
                        <p>
                            <span class="d-lg-none d-md-block">Account</span>
                        </p>
                    </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="account">
                    <a class="dropdown-item" href="javascript:void(0)">Edit Profile</a>
                    <a class="dropdown-item" (click)="logout()">Log out</a>
                  </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
