import {Permissions} from "../models/permissions";

export const AdminROUTES: RouteInfo[] = [
  { path: '/organization', title: 'routes.organization',  icon:'groups', class: '' },
  { path: '/device', title: 'routes.devices',  icon:'device_hub', class: '' },
  { path: '/profile', title: 'routes.account',  icon:'person', class: '' }
];

export const ROUTES: RouteInfo[] = [
  { path: '/organization', title: 'routes.organization',  icon:'groups', class: '' },
  { path: '/profile', title: 'routes.account',  icon:'person', class: '' }
];

export const ROUTESEVENT: RouteInfo[] = [
  { path: '/grid/list', title: 'routes.dashboard',  icon: 'grid_view', class: '' },
  { path: '/map', title: 'routes.map',  icon:'map', class: '' },
  { path: '/analysis/list', title: 'routes.analysis',  icon:'analytics', class: '' },
  { path: '/export', title: 'routes.export',  icon:'download', class: '' }
];

export const ROUTESSETTINGS: RouteInfo[] = [
  { path: '/settings/areas', title: 'routes.areas',  icon:'map', class: '' },
  { path: '/settings/devices', title: 'routes.devices',  icon:'analytics', class: '' },
  { path: '/settings/users', title: 'routes.users',  icon:'download', class: '' },
  { path: '/settings/roles', title: 'routes.roles',  icon:'settings', class: '' },
  { path: '/settings/reset-count-bots', title: 'routes.reset',  icon:'settings', class: '' },
  { path: '/settings/import', title: 'routes.import',  icon:'settings', class: '' },
];


const gridEventRoute =  { path: '/grid/list', title: 'routes.dashboard',  icon: 'grid_view', class: '' };
const mapEventRoute =  { path: '/map', title: 'routes.map',  icon:'map', class: '' };
const analysisEventRoute = { path: '/analysis/list', title: 'routes.analysis',  icon:'analytics', class: '' };
const exportEventRoute = { path: '/export', title: 'routes.export',  icon:'download', class: '' };


const areasSettingRoute =   { path: '/settings/areas', title: 'routes.areas',  icon:'map', class: '' };
const devicesSettingRoute = { path: '/settings/devices', title: 'routes.devices',  icon:'analytics', class: '' };
const usersSettingRoute =  { path: '/settings/users', title: 'routes.users',  icon:'download', class: '' };
const rolesSettingRoute =  { path: '/settings/roles', title: 'routes.roles',  icon:'settings', class: '' };
const resetSettingRoute =  { path: '/settings/reset-count-bots', title: 'routes.reset',  icon:'settings', class: '' };
const importSettingRoute =  { path: '/settings/import', title: 'routes.import',  icon:'settings', class: '' };
export interface RouteInfo {
  path?: string;
  title: string;
  icon: string;
  class: string;
  children?: RouteInfo[]
}

export function generateEventRoutes(permissions: Permissions[]) {
  let Routes: RouteInfo[] = [mapEventRoute];
// Reduce permissions into a map where each key holds an array of codenames
  const permissionMap = reducePermissions(permissions);

  if (permissionMap["eventgridcontainer"]?.includes("view")) {
    Routes.push(gridEventRoute);
  }
  if (permissionMap["eventlogitem"]?.includes("analyse")) {
    Routes.push(analysisEventRoute);
  }
  if (permissionMap["eventlogitem"]?.includes("export")) {
    Routes.push(exportEventRoute);
  }
return Routes;
}


export function reducePermissions(permissions: Permissions[]){
  return permissions.reduce((acc, per) => {
    if (!acc[per.content_type]) {
      acc[per.content_type] = []; // Initialize as an array
    }
    acc[per.content_type].push(per.codename);
    return acc;
  }, {} as Record<string, string[]>);
}
export function generateSettingRoutes(permissions: Permissions[]) {
  let settingRoutes: RouteInfo[] = [];
  const permissionMap = reducePermissions(permissions);

  if (permissionMap["area"] && permissionMap["area"].length > 0) {
    settingRoutes.push(areasSettingRoute);
  }
  settingRoutes.push(devicesSettingRoute);
  if (permissionMap["resetcountbot"] && permissionMap["resetcountbot"].length > 0) {
    settingRoutes.push(resetSettingRoute);
  }
  if (permissionMap["event"]?.includes("change")) {
    settingRoutes.push(usersSettingRoute, importSettingRoute);
  }

  if (permissionMap["role"] && permissionMap["role"].length > 0) {
    settingRoutes.push(rolesSettingRoute);
  }
  return settingRoutes;
}


