import { Component, OnInit, ElementRef } from '@angular/core';
import {Location} from '@angular/common';
import { Router} from '@angular/router';
import {UserService} from "../../shared/services/user.service";
import {EyesConfigurationService} from "../../shared/services/eyes-configuration.service";
import {EyesConfig} from "../../shared/models/eyes-config";
import {CHANGE_LOG_ENTRIES} from "../../shared/utility/change-log";
import {MatDialog} from "@angular/material/dialog";
import {NewsComponent} from "../../shared/components/news/news.component";
import {noop} from "rxjs";
import {User} from "../../auth/user.model";
import {ROUTES} from "../../shared/utility/routers";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
    private listTitles: any[] | undefined;
    location: Location;
      mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible!: boolean;
    configUser!: EyesConfig;
    logs = CHANGE_LOG_ENTRIES;
    newsList: string[] = [];
     userDetails!: User;
    constructor(location: Location,
                private element: ElementRef,
                private router: Router,
                private userService: UserService,
                private dialog: MatDialog,
                private configService: EyesConfigurationService) {
      this.location = location;
          this.sidebarVisible = false;
    }

    ngOnInit(){
      // this.hideMenus();
      this.getUserInfo();
      this.listTitles = ROUTES.filter(listTitle => listTitle);
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
      this.router.events.subscribe((event) => {
        // if(event instanceof NavigationEnd){
        //   this.hideMenus()
        // }
        this.sidebarClose();
         var $layer: any = document.getElementsByClassName('close-layer')[0];
         if ($layer) {
           $layer.remove();
           this.mobile_menu_visible = 0;
         }
     });
  this.getConfigUser();

    }
  isMobileMenu() {
    return window.innerWidth <= 991;
  };

    getUserInfo(){
      this.userService.selectCurrentUser().subscribe(res => {
        if(res){
          this.userDetails = res;
        }
      })
    }

    getConfigUser(){
      this.configService.configDetails().subscribe(res => {
        if(res){
          this.configUser = res;
          if (res?.configuration.read_news === '-1') {

            this.saveNewsStatus(this.configUser)
          }else{
            this.newsList = [];
            this.logs.forEach( date => {
              if(date > this.configUser.configuration.read_news){
                this.newsList.push(date);
              }
            })

          }
        }

      })
    }

    saveNewsStatus(eyesConfig: EyesConfig){
      const body: EyesConfig = {
        ...eyesConfig, configuration: {...eyesConfig.configuration, read_news: this.logs[0]}
      }
      this.configService.edit(eyesConfig.id, body).then(res => {
        this.configService.fetchConfig(Number(this.configUser.user)).subscribe(noop);
      })
    }



    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
      sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const body = document.getElementsByTagName('body')[0];

        if (this.mobile_menu_visible == 1) {
            // $('html').removeClass('nav-open');
            body.classList.remove('nav-open');
            // @ts-ignore
          if ($layer) {
                $layer.remove();
            }
            setTimeout(function() {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function() {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            }else if (body.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function() {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function() { //asign a function
              body.classList.remove('nav-open');
              // @ts-ignore
              this.mobile_menu_visible = 0;
              $layer.classList.remove('visible');
              setTimeout(function() {
                  $layer.remove();
                  $toggle.classList.remove('toggled');
              }, 400);
            }.bind(this);

            body.classList.add('nav-open');
            this.mobile_menu_visible = 1;

        }
    };

    getTitle(){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }

      // @ts-ignore
      for(var item = 0; item < this.listTitles.length; item++){
          // @ts-ignore
        if(this.listTitles[item].path === titlee){
              // @ts-ignore
          return this.listTitles[item].title;
          }
      }
      return 'Dashboard';
    }



  sidebarToggleDesktop() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('sidebar-mini');
  }

  logout() {
    this.userService.logout().then(() => this.router.navigate(['/account/login']));
  }

  openNews() {
    const dialogRefList = this.dialog.open(NewsComponent, {
      width: '500px',
      maxWidth: '100%',
      height: '100%',
      position: {
        right: '0px',
      },
    });
    dialogRefList.afterClosed().subscribe(result => {
    this.saveNewsStatus(this.configUser);
      console.log(this.configUser)


    });
  }
}
