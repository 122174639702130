<div class="logo">
    <a routerLink="/" class="simple-text ">
        <div class="logo-img logo-normal">
            <img src="/assets/img/app-logo.png"/>
        </div>
<!--      <div class="logo-img logo-mini">-->
<!--        <img src="/assets/img/app-icon.png"/>-->
<!--      </div>-->
    </a>

</div>
<div class="sidebar-wrapper">
  <div *ngIf="isMobileMenu()">
    <form class="navbar-form">
      <span class="bmd-form-group">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </span>
    </form>
    <ul class="nav navbar-nav nav-mobile-menu">
<!--        <li class="nav-item">-->
<!--            <a class="nav-link" href="javascript:void(0)">-->
<!--                <i class="material-icons">dashboard</i>-->
<!--                <p>-->
<!--                    <span class="d-lg-none d-md-block">Stats</span>-->
<!--                </p>-->
<!--            </a>-->
<!--        </li>-->
        <li class="nav-item dropdown">
            <a class="nav-link" href="javascript:void(0)" id="notification" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="material-icons">notifications</i>
                <span class="notification">5</span>
                <p>
                    <span class="d-lg-none d-md-block">Some Actions</span>
                </p>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="notification">
                <a class="dropdown-item" href="#">Mike John responded to your email</a>
                <a class="dropdown-item" href="#">You have 5 new tasks</a>
                <a class="dropdown-item" href="#">You're now friend with Andrew</a>
                <a class="dropdown-item" href="#">Another Notification</a>
                <a class="dropdown-item" href="#">Another One</a>
            </div>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)">
                <i class="material-icons">person</i>
                <p>
                    <span class="d-lg-none d-md-block">Account</span>
                </p>
            </a>
        </li>
    </ul>
  </div>
    <ul class="nav">












      <li class=" nav-item" routerLinkActive="active">
        <a  class="nav-link" [routerLink]="'/events'">
          <i class="material-icons">dashboard</i>
          <p>{{'routes.home' | translate}}</p>
        </a>
      </li>

      <li routerLinkActive="active" class="nav-item" *ngIf="eventDetail">
        <a data-toggle="collapse" class="nav-link collapsed" href="#eventItems" aria-expanded="true">
          <i class="material-icons">festival</i>
          <p>{{eventDetail?.title}}<b class="caret"></b></p></a>
        <div class="collapse" id="eventItems" style="">
          <ul class="nav">
            <li routerLinkActive="active" class="nav-item" *ngFor="let menuItem of childrenMenuItem">
              <a class="nav-link" [routerLink]="'event/'+ eventDetail?.id + menuItem.path">
                <span class="sidebar-mini" >
                  <i class="material-icons">{{menuItem.icon}}</i>
                </span>
                <span class="sidebar-normal"> <p>{{menuItem.title | translate}}</p></span></a>
            </li>
            <li routerLinkActive="active" class="nav-item" >
              <a data-toggle="collapse" class="nav-link collapsed" href="#settingItems" aria-expanded="true">
                <i class="material-icons">settings</i>
                <p>{{'routes.settings' | translate}}<b class="caret"></b></p></a>
              <div class="collapse" id="settingItems" style="">
                <ul class="nav">
                  <li routerLinkActive="active" class="nav-item ml-3" *ngFor="let menuItem of settingsMenuItem">
                    <a class="nav-link" [routerLink]="'event/'+ eventDetail?.id + menuItem.path">
<!--                <span class="sidebar-mini" >-->
<!--                         - -->
<!--&lt;!&ndash;                  <i class="material-icons">remove</i>&ndash;&gt;-->
<!--                </span>-->
                      <span class="sidebar-normal"> <p>- {{menuItem.title | translate}}</p></span></a>
                  </li>

                </ul>
              </div>
            </li>

          </ul>
        </div>
       </li>

        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
<!--          <ng-container *ngIf="!menuItem?.children?.length">-->
            <a  class="nav-link" [routerLink]="[menuItem?.path]">
              <i class="material-icons">{{menuItem.icon}}</i>
              <p>{{menuItem.title | translate}}</p>
            </a>


        </li>
      <li class=" nav-item" (click)="logout()">
        <a  class="nav-link cursor-pointer">
          <i class="material-icons">logout</i>
          <p>Logout</p>
        </a>
      </li>
    </ul>
</div>
