<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute fixed-top">
    <div class="container-fluid">
      <button (click)="sidebarToggleDesktop()" class="btn btn-just-icon btn-white btn-fab btn-round" *ngIf="!isMobileMenu()">
        <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
        <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
      </button>
      <button class="navbar-toggler" type="button" (click)="sidebarToggle()">
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
      </button>
        <div class="navbar-wrapper">
          <a class="navbar-brand" href="javascript:void(0)">{{getTitle() | translate}}</a>
        </div>

        <div class="collapse navbar-collapse justify-content-end" id="navigation">

            <ul class="navbar-nav">
                <li class="nav-item dropdown ">
                    <a class="nav-link cursor-pointer"  id="notification" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons">notifications</i>
                        <span class="notification">{{newsList.length}}</span>
<!--                        <p>-->
<!--                            <span class="d-lg-none d-md-block"> {{newsList.length}} {{'news.shortTitle' | translate}}</span>-->
<!--                        </p>-->
                    </a>
                    <div class="dropdown-menu dropdown-menu-right " aria-labelledby="notification" style="min-width: 350px">
                      <ng-container *ngFor="let log of newsList">
                        <a class="dropdown-item " href="javascript:void(0)" (click)="openNews()">
                          <span class="text-secondary">{{(log | slice: 0:10) | date: 'mediumDate'}}</span>  -
                          {{('news.logs.' + log | translate)| slice: 0 : 30}}...</a>
                      </ng-container>
                        <span class=" my-3 d-flex
                         justify-content-center text-primary cursor-pointer text-decoration font-weight-light"
                             (click)="openNews()">{{'seeAll' | translate}}</span>

                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)" id="account" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {{userDetails?.username}}
                        <i class="material-icons">person</i>
                        <p>
                            <span class="d-lg-none d-md-block">{{'routes.account' | translate}}</span>
                        </p>
                    </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="account">
                    <a class="dropdown-item" routerLink="/profile"> {{'account.editProfile' | translate}}</a>
                    <a class="dropdown-item" (click)="logout()">{{'logout' | translate}}</a>
                  </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
