import { Injectable } from '@angular/core';
import {BaseServiceContextService} from "./base-service-context.service";
import {Observable} from "rxjs";
import {HttpParams} from "@angular/common/http";
import {Note} from "../models/note";

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(private baseServiceContext: BaseServiceContextService) { }

  get(organizationId: string) : Observable<Note[]>{
    let queryParams = new HttpParams();
    queryParams = queryParams.append('organization', organizationId);
    return this.baseServiceContext.http
      .get<Note[]>(`${this.baseServiceContext.baseUrl}/notes/`, {params: queryParams})
  }

  getById(id: string) : Promise<Note |undefined>{
    return this.baseServiceContext.http
      .get<Note | undefined>(`${this.baseServiceContext.baseUrl}/notes/${id}/`)
      .toPromise();
  }


  edit(id: number, body: Note) : Promise<Note |undefined>{
    return this.baseServiceContext.http
      .put<Note | undefined>(`${this.baseServiceContext.baseUrl}/notes/${id}/`,body)
      .toPromise();
  }

  create( body: Note) : Promise<Note |undefined>{
    return this.baseServiceContext.http
      .post<Note | undefined>(`${this.baseServiceContext.baseUrl}/notes/`,body)
      .toPromise();
  }

  delete(id: number) : Promise<Note |undefined>{
    return this.baseServiceContext.http
      .delete<Note | undefined>(`${this.baseServiceContext.baseUrl}/notes/${id}/`)
      .toPromise();
  }

}
