import { Component } from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink} from "@angular/router";
import {ChangeLanguageButtonComponent} from "../change-language-button/change-language-button.component";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  imports: [
    TranslateModule,
    RouterLink,
    ChangeLanguageButtonComponent
  ],
  standalone: true
})
export class NotFoundComponent {

}
