import {Injectable} from '@angular/core';
import {BehaviorSubject, noop, Observable, tap} from "rxjs";
import {EventDetail, EventInfo} from "../models/event";
import {BaseServiceContextService} from "./base-service-context.service";
import {Params} from "../models/params";
import {Pageable} from "../models/pageable";
import {HttpParams} from "@angular/common/http";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/app.state";
@Injectable({
  providedIn: 'root'
})
export class EventService {
  eventList: EventInfo[] = [];
  eventListSubject: BehaviorSubject<EventInfo[]> = new BehaviorSubject<EventInfo[]>([]);
  listOfIds: number[] = [];
  public selectedEventSubject = new BehaviorSubject<EventDetail>({});

  constructor(private baseServiceContext: BaseServiceContextService,
              private store: Store<AppState>) {
  }

  getById(id: number): Observable<EventInfo> {
    return this.getEventById(id)
      .pipe(tap(event => {
        if (event) {
          const index = this.eventList?.findIndex(e => e.id === id);
          if (index == -1) {
            this.eventList.push(event);
          }
        }
        this.eventListSubject.next(this.eventList);
      }));
  }

  getEventById(id: number): Observable<EventInfo> {
    return this.baseServiceContext.http
      .get<EventInfo>(`${this.baseServiceContext.baseUrl}/events/${id}/`)
  }


  getEventDetail(id: number): Observable<EventDetail> {
    return this.getEventById(id).pipe(
      tap(event => {
        this.selectedEventSubject.next({ ...this.selectedEventSubject.value, event, id: event?.id });
        this.updateEventDetailProperty('areas', event.areas);
        this.updateEventDetailProperty('passages', event.passages);

        this.getDevicesList(id).then(result => {
          const currentEventDetail: EventDetail = { ...this.selectedEventSubject.value }; // Clone the current event detail
          currentEventDetail.devices = result;
          this.selectedEventSubject.next(currentEventDetail);

        }).catch(error => {
          console.error('Error fetching devices:', error); // Catch and log any errors from devices fetching
        });

      })
    );
  }

   // should be deleted
  getEventsDetailWithOutStore(): Observable<any> {
    return this.selectedEventSubject;
  }

  updateStore(){
    // this.store.dispatch(selectedEvent({ event: this.selectedEventSubject.value }));
  }



  getEventsList(): Observable<EventInfo[]> {
    return this.eventListSubject;
  }
  removeEvent(){
    this.selectedEventSubject.next({});
  }


  private updateEventDetailProperty(propertyName: keyof EventDetail, apiUrl: string) {
    this.baseServiceContext.http
      .get<any>(apiUrl) // Replace 'any' with the expected type
      .pipe(
        tap(result => {
          if (result) {
            const currentEventDetail: EventDetail = { ...this.selectedEventSubject.value }; // Clone to avoid modification issues
            currentEventDetail[propertyName] = result;
            this.selectedEventSubject.next(currentEventDetail);
          } else {
          }
        }),
      )
      .subscribe(noop);
  }




  getDevicesList(eventId: number) : Promise<any>{
    return this.baseServiceContext.http.get<any>(`${this.baseServiceContext.baseUrl}/events/${eventId}/devices/`).toPromise();
  }


  fetchAllData(form: Params) : Promise<Pageable | undefined> {
    let queryParams = new HttpParams();
    if(form){
      queryParams = queryParams.append('page', form?.page!);
      queryParams = queryParams.append('limit', form?.limit!);
      queryParams = queryParams.append('ordering', form?.ordering!);
      queryParams = queryParams.append('search', form?.search || '');
      queryParams = queryParams.append('valid_now', form.valid_now || false);
    }
    return this.baseServiceContext.http
      .get<Pageable | undefined>(`${this.baseServiceContext.baseUrl}/events/`, {params: queryParams})
      .toPromise();
  }

}
