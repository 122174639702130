import { Injectable } from '@angular/core';
import {BaseServiceContextService} from "./base-service-context.service";
import {BehaviorSubject, map, Observable} from "rxjs";
import {EyesConfig} from "../models/eyes-config";
import {HttpParams} from "@angular/common/http";
import {Note} from "../models/note";

@Injectable({
  providedIn: 'root'
})
export class EyesConfigurationService {

  private eyesConfig: BehaviorSubject<EyesConfig | null> = new BehaviorSubject<EyesConfig | null>(null);

  constructor(private baseServiceContext: BaseServiceContextService,) { }


  public fetchConfig(userId: number): Observable<null> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('user', userId);
    return this.baseServiceContext.http.get<EyesConfig[]>(`${this.baseServiceContext.baseUrl}/eyes-configurations/`,
      {params:queryParams}).pipe(map((entries : EyesConfig[]) => {
      this.eyesConfig.next(entries[0]);
      return null;
    }))
  }

  public configDetails(): Observable<EyesConfig | null> {
    return this.eyesConfig;
  }

  edit(id: number, body: EyesConfig) : Promise<EyesConfig |undefined> {
    return this.baseServiceContext.http
      .put<EyesConfig | undefined>(`${this.baseServiceContext.baseUrl}/eyes-configurations/${id}/`,body)
      .toPromise();
  }

}
