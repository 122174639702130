<h2 mat-dialog-title> {{mode == 'CREATE'? ('organizations.notes.addNote' | translate) : ('doEdit' | translate)}}</h2>

<mat-dialog-content class="mat-typography">
  <form class="mt-2" [formGroup]="form" >

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="mb-2 input-custom">
        <mat-label>{{'organizations.notes.title' | translate}}</mat-label>
        <input matInput type="text" name="title" formControlName="title">

      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="mb-2 input-custom">
        <mat-label>{{'organizations.notes.read' | translate}}</mat-label>
        <mat-select  formControlName="members_can_read">
          <mat-option
            *ngFor="let option of readOptions" [value]="option.value">
            <span> {{'organizations.notes.' + option.label | translate}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field appearance="outline" class="mb-2 input-custom">
        <mat-label>{{'organizations.notes.write' | translate}}</mat-label>
        <mat-select formControlName="members_can_write">
          <mat-option *ngFor="let option of writeOptions" [value]="option.value">
            <span> {{'organizations.notes.' + option.label | translate}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div class="col-12 mb-4">
      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
      <ngx-editor [editor]="editor" formControlName="content"></ngx-editor>
    </div>


    <button [disabled]="!form.dirty"  (click)="OnSubmit()" class="btn btn-primary pull-right">{{'doSave' | translate}}</button>
    <button  class="btn btn-secondary pull-right mr-2" (click)="cancel()">{{'doCancel' | translate}}</button>
    <div class="clearfix"></div>
  </form>

</mat-dialog-content>



