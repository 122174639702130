import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {Note} from "../../../../shared/models/note";
import {Editor, NgxEditorModule, Toolbar} from "ngx-editor";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {marked} from "marked";
import TurndownService from 'turndown';
import {MatFormFieldModule} from "@angular/material/form-field";
import {TranslateModule} from "@ngx-translate/core";
import {MatSelectModule} from "@angular/material/select";
import {NgForOf} from "@angular/common";
import {MatInputModule} from "@angular/material/input";

@Component({
  selector: 'app-add-note-form',
  templateUrl: './add-note-form.component.html',
  styleUrls: ['./add-note-form.component.scss'],
  imports: [
    MatFormFieldModule,
    TranslateModule,
    MatSelectModule,
    NgForOf,
    ReactiveFormsModule,
    NgxEditorModule,
    MatDialogModule,
    MatInputModule
  ],
  standalone: true
})
export class AddNoteFormComponent implements OnInit, OnDestroy{
  form!: FormGroup;
  editor!: Editor;

  mode: 'CREATE' | 'UPDATE' = 'CREATE'
  readOptions = [
    {label: 'all' , value: true},
    {label: 'admins' , value: false}
  ];

  writeOptions = [
    {label: 'all' , value: 1 },
    {label: 'admins' ,value: 2},
    {label: 'owners' , value: 3}
  ];
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ]
  constructor(private fb: FormBuilder,
              public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.editor = new Editor();
    this.createForm();
    if(this.data){
      this.mode = this.data.mode;
      this.updateForm(this.data.details)
    }
  }

  createForm() {
    this.form = this.fb.group({
      title: [''],
      members_can_read: [true],
      members_can_write: [2],
      content: ['']
    });
  }

  updateForm(data: Note) {
    this.form.get('title')?.patchValue(data?.title);
    this.form.get('members_can_read')?.patchValue(data?.members_can_read);
    const writeInput = this.convertWriteInputToForm({members_can_write: data?.members_can_write!,admins_can_write: data?.admins_can_write!,})
    this.form.get('members_can_write')?.patchValue(writeInput);
    const htmlContent = this.convertMarkdownToHtml(data?.content);
    this.form.get('content')?.patchValue(htmlContent);
  }

  convertWriteInputToSubmit(number: Number){
    let message;
    switch (number) {
      case 1:
      message = {members_can_write: true,admins_can_write: true }
        break
      case 2:
         message =  {members_can_write: false,admins_can_write: true }
        break
      case 3:
         message =  {members_can_write: false,admins_can_write: false }
        break
    }
    return message
  }

  convertWriteInputToForm(data: { members_can_write: boolean,admins_can_write: boolean }){
    let answer = 1;
    if(data.members_can_write){
      answer = 1
    }else if (!data.members_can_write && data.admins_can_write){
      answer = 2
    }else if (!data.admins_can_write){
      answer = 3
    }
    return answer

  }


  OnSubmit() {
    const formData = this.form.value;
    // Convert HTML content back to Markdown before saving
    const markdownContent = this.convertHtmlToMarkdown(formData.content);
    const writeOptions = this.convertWriteInputToSubmit(formData.members_can_write);
    const note: Note = {
      title: formData.title,
      members_can_read: formData.members_can_read,
      members_can_write: writeOptions?.members_can_write,
      admins_can_write: writeOptions?.admins_can_write,
      content: markdownContent // Save Markdown format
    };

    this.dialogRef.close(note);
  }

  convertMarkdownToHtml(markdown: string): string {
    return <string>marked.parse(markdown);
  }

  convertHtmlToMarkdown(html: string): string {
    const turndownService = new TurndownService();
    return turndownService.turndown(html);
  }

  cancel() {
  this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
